import { getServicesByRoute, hasPricingMenu } from '~/utils/memberships'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $bmAuth, $membershipApi } = useNuxtApp()
  const requestedPath = to.path
  const authenticated = $bmAuth.isAuthenticated()
  const routeMarket = requestedPath.split('/').slice(1, 4).join('/')

  if (!authenticated) return navigateTo(`/${routeMarket}`)

  const memberships = await $membershipApi.getActiveMemberships(false)
  const membershipsData = getServicesByRoute(requestedPath)
  const subscriptionsActive = membershipsData?.filter((membership) =>
    memberships.includes(membership.subscription),
  )

  const suffixes = subscriptionsActive.map((membership) => {
    if (membership.subscription?.split('-')[0] === 'esg') {
      return 'sustainability'
    }
    if (membership.subscription?.split('-')[0] === 'pricing') {
      return 'price-assessments'
    }
    return membership.subscription?.split('-')[0]
  })

  const hasCoincidences = suffixes?.some((suffix) =>
    requestedPath.includes(suffix),
  )
  let hasSubscriptionActive = subscriptionsActive?.length > 0

  if (requestedPath.includes('price-dashboard') && !hasSubscriptionActive) {
    hasSubscriptionActive = hasPricingMenu(memberships)
  }

  if (!hasSubscriptionActive || !hasCoincidences) {
    let routeFinal = routeMarket

    if (routeMarket.endsWith('//')) {
      routeFinal = routeMarket.substring(-1)
    } else if (!routeMarket.endsWith('/')) {
      routeFinal = `${routeMarket}/`
    }

    return navigateTo(`/${routeFinal}`)
  }
})
